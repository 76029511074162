export const seo = {
  url: 'uslugi',
  title: {
    pl: 'Usługi',
    en: 'Services',
    ua: 'Послуги',
  },
  desc: {
    pl: `Doświadczeni spedytorzy w Omida Sea And Air zadbają o profesjonalną obsługę Twojego transportu.`,
    en: `Experienced forwarders at Omida Sea And Air will take care of professional service of your transport`,
    ua: `Досвідчені експедитори Omida Sea And Air подбають про професійне обслуговування вашого транспорту.`,
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'spedycja', 'usługi'],
}

export const intro = {
  title: {
    pl: 'Usługi',
    en: 'Services',
    ua: 'Послуги',
  },
  desc: {
    pl: `Doświadczeni spedytorzy w Omida Sea And Air zadbają o profesjonalną obsługę Twojego transportu.`,
    en: `Experienced forwarders at Omida Sea And Air will take care of professional service of your transport`,
    ua: `Досвідчені експедитори Omida Sea And Air подбають про професійне обслуговування вашого транспорту.`,
  },
}

export const main = {
  title: {
    pl: 'Usługi frachtu.<br/>Wybierz jedną spośród wielu usług spedycyjnych i logistycznych',
    en: 'Freight forwarding.<br/>Choose from a wide range of forwarding and logistics services',
    ua: 'Вантажні послуги.<br/>Виберіть із різноманітних послуг доставки та логістики',
  },
  body: {
    pl: `
    <p>
    Usługi spedycyjne to wszelkie czynności mające na celu zorganizowanie transportu towarów. Jej celem jest doprowadzenie do <strong>bezpiecznego i efektywnego</strong> dostarczenia ich do miejsca docelowego wskazanego przez Klienta.
    </p>
    <p>
    W zakresie usług spedycyjnych mieści się również <strong>przechowanie</strong>, <strong>przepakowanie</strong>, <strong>załadunek</strong>, <strong>ubezpieczenie</strong>, ewentualna <strong>odprawa celna</strong> oraz <strong>rozładunek</strong> u odbiorcy towarów.
    </p>
    `,
    en: `
    <p>
    Forwarding services are all activities aimed at organizing the transport of goods. Its goal is to ensure <strong>safe and effective</strong> delivery to the destination indicated by the customer.
    </p>
    <p>
    The scope of forwarding services also includes <strong>storage</strong>, <strong>repackaging</strong>, <strong>loading</strong>, <strong>insurance</strong>, possible <strong>customs clearance</strong> and <strong>unloading</strong> at the recipient of the goods.
    </p>
    `,
    ua: `
    <p>
    Транспортно-експедиторські послуги — це всі види діяльності, спрямовані на організацію перевезення вантажів. ЇХ мета — забезпечити безпечну та ефективну доставку до пункту призначення, вказаного клієнтом.
    </p>
    <p>
    В обсяг експедиторських послуг також входить <strong>зберігання</strong>, <strong>перепаковка</strong>, <strong>навантаження</strong>, <strong>страхування</strong>, можливе <strong>розмитнення< /strong> та <strong>вивантаження</strong> у одержувача товару.
    </p>
    `,
  },
}
